import { BaseHTMLAttributes, FC, memo } from "react"
import { Icon, IconNameType } from "../../ui/Icon"
import { StyledItemDescription, StyledItemTitle } from "../StyledList"
import {
  StyledContent,
  StyledPictureList,
  StyledPicture,
  StyledPictureListItem,
} from "./Styled"

export type PictureListItemType = {
  icon?: IconNameType
  title: string
  description: string
}

export const PictureList: FC<
  {
    items: PictureListItemType[]
  } & BaseHTMLAttributes<HTMLUListElement>
> = memo(({ items, ...props }) => {
  return (
    <StyledPictureList {...props}>
      {items.map((item, index) => (
        <StyledPictureListItem key={index}>
          {item.icon !== undefined && (
            <StyledPicture>
              <Icon NameIcon={item.icon} size={"largeM"} />
            </StyledPicture>
          )}
          <StyledContent>
            <StyledItemTitle>{item.title}</StyledItemTitle>
            <StyledItemDescription>{item.description}</StyledItemDescription>
          </StyledContent>
        </StyledPictureListItem>
      ))}
    </StyledPictureList>
  )
})

PictureList.displayName = "PictureList"
